.header {
    width:100%;
    display: flex;
    flex-direction: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    z-index: 9;
    overflow:hidden;
}

.header-logo img{
    max-width: 200px;
    border-radius: 0px 0px 40px 40px;
    background-color: rgb(255, 255, 255);
    padding:10px 30px 10px 30px;
    max-height: 80px;
    margin-left: -4.5px;
    margin-right: -4.5px;
}

.full_width {
    width: 100%;
}

.avatarContainer {
  background-color: rgb(255, 132, 0);
}

.min400 {
    height: 400px;
}

.min400 img {
  width:100%;
  display: block;
}

.border_right {
    margin-left:auto;
    width:60%;
    height: 50px;
    overflow: hidden;
    position: relative;
}

  .border_left {
    margin-right:auto;
    width:60%;
    height: 50px;
    overflow: hidden;
    position: relative;
  }

  .border_left:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    box-shadow: -50px -50px 0 0 rgb(255, 255, 255);
  }

  .border_right:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 0;
    box-shadow: 50px -50px 0 0 rgb(255, 255, 255);
  }

  .profile_container {
    width:100%;
    overflow: hidden;
    position:relative;
    top: -20px;
  }

  .profile {
    border-radius: 25px 25px 0px 0px;
    background-color: white;
    padding:30px;
  }

/* SaveContactButton */

.SaveContactBtn {
    position:fixed;
    bottom: 30px;
    width:100%;
    text-align: center;
    position: relative;
  }

.SaveBtn  {
  background-color: rgb(245, 126, 0);
  padding: 15px 15px 10px 15px;
  border-radius: 25px;
  padding:10px;
  color:white;
  margin:0px 25px;
    /* text-align: center; */

}

.SaveBtn button {
  border:none;
  background-color: transparent;
  font-size: 1.2em;
  color: white;
  
}

.contact_wrapper {
  padding:15px 25px 5px 25px;
  background-color: rgb(244, 244, 244);
  border-radius: 25px;
}

.rounded-icon {
  line-height: 1.5em;
  color:rgb(49, 49, 49);
}

.smaller-email {
  font-size: .9em;
}

p {
  color:rgb(75, 75, 75);
  line-height: 1.5em;
}

.horizontal_border {
  border-left: 3px solid black;
  padding:0px 15px 0px 15px;
  margin:10px;
}

h3 {
  font-weight: 700;
  font-size: 1.3em;
}

.spacer {
  height: 60px;
}