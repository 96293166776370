.container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bgyellow {
    background-color: rgb(255, 175, 69);
    background-image: url(../assets/images/gwf-phone.png);
    object-fit: contain;
}

.form-container {
    width: 100%;
}

/* FORM CSS */
.form-group {
    margin-bottom: 15px;
}

.form-control {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    color: #262626;
    padding:30px;
    margin:20px;
    background-color: #ffffffea;
    border-radius: 25px;
}

input {
    padding:10px;
    display: block;
    border: solid 1px #ccc;
    border-radius: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    width: 100%;

    /* box sizing */
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;    
}

#error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
    display: none;
}

.show {
    display: block !important;
}

button  {
    background-color: rgb(245, 126, 0);
    padding: 15px;
    border-radius: 25px;
    color:white;
    border: none;
    font-size: 1em;
    width: 100%;
  }

.center {
    text-align: center;
    margin: auto;
}

.spacer {
    height: 50px;
}

.form-container {
    color: white;
}