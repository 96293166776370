@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
}


h3, h4, p {
  line-height:.5em;
  
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.orange {
  color: #FFA500;
}